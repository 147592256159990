<template>
  <div class="container">
    <h1>Face landmark detection using the MediaPipe FaceLandmarker task</h1>

  </div>
</template>
<script>
export default {
  name: 'Fa-ce',
};
</script>
