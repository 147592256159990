<template>
  <div class="home-container mt-5 pt-5">
    <div class="content-wrapper">
      <img class="portrait-image" :src="portraitUrl" alt="Portrait">
      <div class="text-content">
        <h1>Augmented Reality with IVIS ARVR</h1>
        <p class="typewriter">Transforming Your World, One Layer at a Time</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'Ho-me',
  data() {
    return {
      portraitUrl: 'https://nettedimages.s3.ap-south-1.amazonaws.com/AR.jpg'
    };
  }
};
</script>

<style scoped>
.typewriter {
  width: 300px; /* Adjust this to control the width of the container */
  white-space: pre-wrap; /* This ensures that the text wraps */
  overflow: hidden; /* Ensures that the content doesn't spill outside */
  border-right: .15em solid orange; /* The typewriter cursor */
  font-family: 'Courier New', Courier, monospace;
  animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange }
}


.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

.content-wrapper {
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  gap: 20px;
}

.portrait-image {
  flex-shrink: 0; /* Prevents the image from shrinking */
  width: 40%; /* Adjusted width */
  max-height: 80vh;
  height: auto;
  border-radius: 10px;
}

.text-content {
  flex: 1;
  text-align: left;
  max-width: 55%; /* Limit the width to allow text wrapping */
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .portrait-image {
    width: 80%;
    margin-bottom: 20px;
  }

  .text-content {
    text-align: center;
    max-width: 100%; /* Allow text to take full width on mobile */
  }
}
</style>
