<template>
  <footer class="custom-footer">
      <div class="contact-details">
        <span class="text-muted">        <img src="https://nettedimages.s3.ap-south-1.amazonaws.com/IVISARVR_logo.png" alt="IVIS ARVR" width="100"> by IVIS LABS Pvt. Ltd. © 2023</span>

        <span class="text-muted">
          <i class="fas fa-envelope"></i> email: <a href="mailto:info@ivislabs.com">info@ivislabs.com</a>
        </span>
        <span class="text-muted">
          <i class="fas fa-phone-alt"></i> mobile: +91 6364411444
        </span>
        <span class="text-muted">
          <i class="fas fa-globe"></i> <a href="https://ivislabs.com" target="_blank">ivislabs.com</a>
        </span>
      </div>
  </footer>
</template>
<script>
export default {
  name:'Foo-ter',
  }
</script>
<style scoped>
.custom-footer {
  background-color: #fcfcfc; /* Dark blue color */
  padding: 20px 0;
  color: #ecf0f1; /* Light text color */
}

.contact-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.contact-details span {
  margin: 0 10px;
}

.contact-details a {
  color: #000000;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}
</style>
