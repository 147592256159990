<template>
  <div class="container mt-5 pt-5">
    <h1>3D Object Placement on User-Marked Area</h1>
    <button v-if="showStartButton" @click="startVideo" class="btn btn-primary">Start Video</button>
    <div v-if="showCanvas" ref="canvasContainer" @click="placeObject"></div>
  </div>
</template>

<script>
import * as THREE from 'three';

export default {
  data() {
    return {
      showCanvas: false,
      showStartButton: true,
      video: null,
      objectPosition: null,
      scene: null,
      camera: null,
      renderer: null,
      cube: null
    };
  },
  methods: {
    async startVideo() {
      this.showStartButton = false;
      this.showCanvas = true;

      this.video = document.createElement("video");
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      this.video.srcObject = stream;
      await this.video.play();

      // Three.js setup
      const container = this.$refs.canvasContainer;
      this.camera = new THREE.PerspectiveCamera(75, container.offsetWidth / container.offsetHeight, 0.1, 1000);
      this.scene = new THREE.Scene();

      const texture = new THREE.VideoTexture(this.video);
      const backgroundMesh = new THREE.Mesh(
        new THREE.PlaneGeometry(2, 2),
        new THREE.MeshBasicMaterial({ map: texture })
      );
      backgroundMesh.material.depthTest = false;
      backgroundMesh.material.depthWrite = false;
      this.scene.add(backgroundMesh);

      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setSize(container.offsetWidth, container.offsetHeight);
      container.appendChild(this.renderer.domElement);

      // Create a 3D cube
      const geometry = new THREE.BoxGeometry();
      const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
      this.cube = new THREE.Mesh(geometry, material);
      this.scene.add(this.cube);

      this.camera.position.z = 5;

      const animate = () => {
        requestAnimationFrame(animate);
        this.renderer.render(this.scene, this.camera);
      };
      animate();
    },
    placeObject(event) {
      // Set the cube's position based on the click event
      this.cube.position.set(event.offsetX / 100 - 3, -(event.offsetY / 100 - 2), 0);
    }
  }
};
</script>
