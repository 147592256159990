<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-white">
    <div class="container-fluid ">
      <RouterLink class="nav-link" to="/">
        <img src="https://nettedimages.s3.ap-south-1.amazonaws.com/IVISARVR_logo.png" alt="IVIS ARVR" width="200">  </RouterLink>  
           
          <button 
        class="navbar-toggler" 
        type="button" 
        data-bs-toggle="collapse" 
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup" 
        aria-expanded="false" 
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end  py-2" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <RouterLink class="nav-link" to="/face-augmentation">
            Face Augmentation
          </RouterLink>  
          <RouterLink class="nav-link" to="/location-ar">
            Location AR
          </RouterLink>  
          <RouterLink class="nav-link" to="/object-placement">
            Object Placement
          </RouterLink>
          <RouterLink class="nav-link" to="/marked-ar">
            Marked AR
          </RouterLink>
        </div>
      </div>
    </div>
  </nav>
  </template>
  
  <script>
  
  export default {
    name: "Nav-bar",
  
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  </style>