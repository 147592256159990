import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import FaceAugmentation from '../views/Face.vue';
import LocationAR from '../views/LocationAR.vue';
import ObjectPlacement from '../views/ObjectPlacement.vue';
import MarkedAR from '../views/MarkedAR.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/face-augmentation', component: FaceAugmentation },
  { path: '/location-ar', component: LocationAR },
  { path: '/object-placement', component: ObjectPlacement },
  { path: '/marked-ar', component: MarkedAR }
];

const router = createRouter({
  history: createWebHistory(),
  base: '/ivisarvr/',  // Set the base property here
  routes
});

export default router;
