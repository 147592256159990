<template>
    <div class="container mt-5 pt-5">
        <h1>Location-Based Object Placement in Augmented Reality</h1>
    <button v-if="showARButton" @click="startAR" class="btn btn-primary">Start AR Experience</button>
    <canvas v-if="showCanvas" ref="canvas"></canvas>
    <p v-if="message">{{ message }}</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showCanvas: false,
        showARButton: true,
        message: ''
      };
    },
    methods: {
      async startAR() {
        this.showARButton = false;
        this.showCanvas = true;
  
        // Define a target location (latitude and longitude)
        const targetLocation = {
          latitude: 12.335841790966636,  // Example: New York City
          longitude: 76.61877718122795
        };
  
        // Get the current geolocation
        navigator.geolocation.getCurrentPosition((position) => {
          const distance = this.calculateDistance(targetLocation, position.coords);
          console.log(distance);
          if (distance < 100) { // 100 meters for this example
            this.message = 'You are close to the target location!';
          } else {
            this.message = `You are ${distance.toFixed(2)} meters away from the target location.`;
          }
        });
      },
      calculateDistance(target, current) {
        const R = 6371e3; // Earth radius in meters
        const lat1 = this.toRadians(current.latitude);
        const lat2 = this.toRadians(target.latitude);
        const deltaLat = this.toRadians(target.latitude - current.latitude);
        const deltaLon = this.toRadians(target.longitude - current.longitude);
  
        const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
                  Math.cos(lat1) * Math.cos(lat2) *
                  Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
        return R * c; // Distance in meters
      },
      toRadians(degrees) {
        return degrees * (Math.PI / 180);
      }
    }
  };
  </script>
  